import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {getStorage, getDownloadURL, ref, uploadBytesResumable} from 'firebase/storage'
import { app } from '../firebase';
import { updateUserInFaliure, updateUserInStart, updateUserInSuccess, deleteUserFaliure, deleteUserStart,deleteUserSuccess,signOut} from '../redux/user/userSlice';
import { useDispatch } from 'react-redux';

function Profile() {
  const fileRef = useRef(null);
  const [image, setImage] = useState(undefined);
  const [imagePercent, setImagePercent] = useState(0);
  const [imageError, setImageError] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const {currentUser, loading, error} = useSelector(state => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    if (image) {
      handleFileUpload(image);
    }
  }, [image]);

  const handleFileUpload = async (image) => {
    const storage = getStorage(app);
    const fileName = new Date().getTime() + image.name;
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, image);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setImagePercent(Math.round(progress));
      },
      (error) => {
        setImageError(true);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>
          setFormData({ ...formData, profilePicture: downloadURL })
        );
      }
    );
  };

  const handleChange = (e) =>{
    setFormData({...formData, [e.target.id]: e.target.value});
  };
  
  const handleSubmit = async (e) =>{
    e.preventDefault();
    try 
    {
      dispatch(updateUserInStart())
      const res = await fetch(`/api/user/update/${currentUser._id}`, {
        method:'POST',
        credentials: 'include',
        headers:{
          'Content-Type': 'application/json',
        },
        body:JSON.stringify(formData)
      });
      const data = await res.json();
      if(data.success === false)
      {
        dispatch(updateUserInFaliure(data));
        return
      }
      dispatch(updateUserInSuccess(data));
      setUpdateSuccess(true);
    } catch (error) 
    {
      dispatch(updateUserInFaliure(error))
    }
  }

  const handleDeleteAccount = async () =>{
    try {
      dispatch(deleteUserStart())
      const res = await fetch(`/api/user/delete/${currentUser._id}`, {
        method:'DELETE',
        credentials: 'include',
        headers:{
          'Content-Type': 'application/json',
        },
      });
      const data = await res.json();
      if(data.success === false)
      {
        dispatch(deleteUserFaliure(data));
        return;
      }
      dispatch(deleteUserSuccess(data));
    } catch (error) {
      dispatch(deleteUserFaliure(error));
    }
  }

  const handleSignOut = async () => {
    try {
      await fetch('/api/auth/signout', {
        method:'GET',
        credentials: 'include',
        headers:{
          'Content-Type': 'application/json',
        },
      });
      dispatch(signOut())
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='p-3 max-w-lg mx-auto'>
      <h1 className='text-3xl font-semibold text-center my-7'>Profile</h1>
      <form onSubmit={handleSubmit} className='flex flex-col gap-4'>
        <input type='file' ref={fileRef} hidden accept='image/*' onChange={(e) =>setImage(e.target.files[0])}/>
        <img src={formData.profilePicture || currentUser.profilePicture} alt='profile' className='h-24 w-24 self-center cursor-pointer rounded-full object-cover'
         onClick={() => fileRef.current.click()}
        />
        <p className='text-sm self-center'>
        {imageError
          ?
          (<span className='text-red-700'>Error uploading image (File size must be less than 2 MB)</span>)
          :
          (
            (imagePercent > 0 && imagePercent < 100 )
            ? (<span className='text-slate-700'>{`Uploading: ${imagePercent} %`}</span> )
            : (imagePercent === 100) ? (<span className='text-green-700'> Image is uploaded successfully </span>) : ''
          )
        }
        </p>
        <input type="text" id='username' placeholder='Username' value={formData.username || currentUser.username} className='bg-slate-100 rounded-lg p-3'
        onChange={handleChange}/>
        <input type="email" id='email' placeholder='Email' value={formData.email || currentUser.email} className='bg-slate-100 rounded-lg p-3'
        onChange={handleChange}/>
        <input type="password" id='password' placeholder='Password' value={formData.password} className='bg-slate-100 rounded-lg p-3'
        onChange={handleChange}/>
        <button className='text-white p-3 rounded-lg uppercase hover:opacity-95 disabled:opacity-80 bg-slate-700'>{loading ? 'Loading...' : 'Update'}</button>
      </form>
      <div className='flex justify-between mt-5'>
        <span onClick={handleDeleteAccount} className='text-red-700 cursor-pointer'>Delete Account</span>
        <span onClick={handleSignOut} className='text-red-700 cursor-pointer'>Sign Out</span>
      </div>
      <p className='text-red-700 mt-5'>
        {error && "Something went wrong! "}
      </p>
      <p className='text-green-700 mt-5'>
        {updateSuccess && "User is updated successfully! "}
      </p>
    </div>
  )
}

export default Profile